@import '../../../scss/helpers';

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $main-top-padding $main-left-padding 0 $main-left-padding;

  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .title {
      margin-right: 12px;
    }
  }
}
