$color-grey: #949494; // $color-text-secondary
$color-main: #222;
$color-red-bg: #c0283c;
$color-link: #ff2e00;

$header-pending: 22px 23px 20px 23px;
$header-bg: #2e2e2e;
$header-color: #fff;
$header-height: 84px;
$header-menu-active-color: $color-link;

$main-pending: 44px 0;
$main-bg: #f4f4f4;
$main-content-bg: #fff;
$main-top-padding: 24px;
$main-left-padding: 32px;
