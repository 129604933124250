@import '../../../scss//helpers';

.profileMenuWrapper {
  .nameUserLink {
    line-height: 1;
    color: $header-color;
    text-decoration: none;

    .nameUser {
      margin-left: 8.5px;
    }
  }
}
