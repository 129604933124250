@import '../../../scss/helpers';

.name {
  @include font_bold_text;
}

.email {
  @include font_sub_text;
  margin-top: 2px;
}
