@import '../../../scss/helpers';

.menuList {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;

  .menuListItem {
    margin-right: 55px;
    line-height: 1;

    &:last-child {
      margin-right: 0;
    }

    .menuLink {
      @include font_menu_link;
      text-decoration: none;
      opacity: 0.8;

      svg {
        margin-right: 10px;

        path {
          fill: $header-color;
          fill-opacity: 0.8;
        }
      }

      &.menuLinkActive {
        opacity: 1;
        font-weight: 700;

        path {
          fill: $header-menu-active-color;
          fill-opacity: 1;
        }
      }
    }
  }
}
