@import '../../../scss/helpers';

.link {
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: $header-menu-active-color;
  }
}

.btnDanger {
  @include font_btn_danger;
  cursor: pointer;
  background-color: $color-red-bg;
  padding: 7.5px 19.5px;
  border-radius: 4px;
  border: 0.5px solid $color-red-bg;
  transition: 0.2s linear;

  &:hover {
    background-color: #fff;
    color: $color-red-bg;

    svg {
      path {
        fill: $color-red-bg;
      }
    }
  }

  &:hover.btnLoading,
  &:hover.btnDisabled {
    color: #fff;
    background-color: $color-red-bg;
    padding: 7.5px 19.5px;
    border-radius: 4px;
    border: 0.5px solid $color-red-bg;
    transition: 0.2s linear;
  }
}

.blackUrl {
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 600;
}

.btnResetFilter {
  color: $color-red-bg;
  vertical-align: sub;
}

.outBgBtn {
  @include font_table_td;
  cursor: pointer;
  background-color: #fff;
  padding: 6px 19.5px;
  margin-left: -1px;
  position: relative;
  z-index: 9;
  border-radius: 0 4px 4px 0;
  border: 0.5px solid $color-red-bg;
  color: $color-red-bg;
  transition: 0.2s linear;
  display: flex;
  align-items: center;

  & > span {
    margin-right: 9px;
  }

  svg {
    path {
      fill: $color-red-bg;
    }
  }

  &:hover {
    color: $color-red-bg;

    svg {
      path {
        fill: $color-red-bg;
      }
    }
  }
}

.btnRound {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 4.9px 0;
  // border: 0.5px solid $color-grey;
  color: $color-main;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      fill: $color-main;
    }
  }

  &:hover {
    color: $color-red-bg;
    border: 0.5px solid $color-red-bg;

    svg {
      path {
        fill: $color-red-bg;
      }
    }
  }
}

.btnLoading,
.btnDisabled {
  opacity: 0.3;
  cursor: not-allowed;

  &:hover {
    color: inherit;
    background: inherit;
    border: inherit;
  }
}

.btnArchive {
  @include font_btn_danger;
  border: 0.5px solid $color-red-bg;
  color: $color-red-bg;
  border-radius: 4px;
  padding: 8px 24px;
  display: inline-block;

  &:hover {
    color: $color-main;
    border: 0.5px solid $color-main;

    svg {
      path {
        fill: $color-main;
      }
    }
  }

  &:hover.btnLoading,
  &:hover.btnDisabled {
    color: $color-main;
    border: 0.5px solid $color-main;
  }
}

.antFormSave {
  @include font_btn_danger;
  color: #fff;
  background-color: $color-red-bg;
  border-color: $color-red-bg;
  border-radius: 4px;

  &:hover {
    background-color: #fff;
    border-color: $color-red-bg;
    color: $color-red-bg;
  }

  &:focus {
    color: #fff;
    background-color: $color-red-bg;
    border-color: $color-red-bg;
  }
}

.antFormArchive {
  @include font_btn_danger;
  color: $color-red-bg;
  background-color: #fff;
  border-color: $color-red-bg;
  border-radius: 4px;

  &:hover {
    background-color: $color-red-bg;
    border-color: $color-red-bg;
    color: #fff;

    svg {
      path {
        fill: #fff;
      }
    }
  }

  &:focus {
    color: $color-red-bg;
    background-color: #fff;
    border-color: $color-red-bg;
  }
}

.antFormLoadMore {
  @include font_btn_danger;
  color: $color-main;
  background-color: #fff;
  border: 0.5px solid $color-main;
  border-radius: 4px;

  &:hover {
    background-color: #fff;
    border-color: $color-red-bg;
    color: $color-red-bg;

    svg {
      path {
        fill: $color-red-bg;
      }
    }
  }

  &:focus {
    color: $color-red-bg;
    background-color: #fff;
    border-color: $color-red-bg;
  }
}
