@import 'variables.scss';

@mixin font_bold_text {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  color: $color-main;
}

@mixin font_bold_logo {
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
}

@mixin font_sub_text {
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 12px;
  color: $color-main;
}

@mixin font_btn_danger {
  font-family: 'Open Sans';
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
}

@mixin font_menu_link {
  font-family: 'Open Sans';
  font-size: 14px;
  color: $header-color;
}

@mixin font_table_th {
  font-family: 'Open Sans';
  font-weight: 700px;
  font-size: 10px;
  color: $color-grey;
  text-transform: uppercase;
}

@mixin font_table_td {
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  color: $color-main;
}

@mixin font_tag {
  font-family: 'Open Sans';
  color: rgba(0, 0, 0, 0.9);
  font-weight: 400;
  font-size: 12px;
}

@mixin font_h2 {
  font-weight: 800;
  font-size: 24px;
  line-height: 1;
  color: $color-main;
}

@mixin font_h3 {
  font-weight: 800;
  font-size: 24px;
  line-height: 1;
  color: $color-main;
}
