@import '../../../scss/helpers';

.tag {
  @include font_tag;

  padding: 5px 7px;
  background-color: #f3f3f3;
  margin-right: 7px;

  &:last-child {
    margin-right: 0;
  }
}
