@import '../../../scss/helpers';
.logoWrapper {
  display: flex;

  .logoImageWrapper {
    .logoImage {
      width: 100%;
    }
  }

  .logoTitle {
    .logoTitleUp {
      display: block;
      @include font_bold_logo;
      color: #fff;
    }

    .logoTitleDown {
      @include font_sub_text;
      line-height: 22px;
      color: #fff;
    }
  }
}