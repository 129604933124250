@import '../../../scss/helpers';

.tableFilterWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 47px;
  padding-right: $main-left-padding;
  padding-left: $main-left-padding;

  & > div,
  & > span {
    margin-right: 11px;
  }
}
