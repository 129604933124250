.table {
  border: none;
  width: 100%;

  thead,
  tbody,
  tr,
  th,
  td {
    border-spacing: 0;
    border-collapse: inherit;
    -webkit-border-horizontal-spacing: 0;
    -webkit-border-vertical-spacing: 0;
    text-align: left;
  }
}
