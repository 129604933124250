.spinnerWrapper {
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinnerBtn {
  margin-right: 10px;
}
