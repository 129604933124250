@import '../../../scss/helpers';

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $header-pending;
  background: $header-bg;
  color: $header-color;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  height: $header-height;
}
