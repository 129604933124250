@import '../scss/base';
@import '../scss/helpers';
@import '../../node_modules/antd/dist/antd.css';

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  font: normal normal 400 14px /19.07px 'Open sans', sans-serif;
  color: #000;
  background: $main-bg;
}

img,
svg {
  vertical-align: middle;
}

h2 {
  @include font_h2;
  margin: 0;
}

.ant-dropdown {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  & > ul {
    li {
      cursor: auto;

      &.ant-menu-item:not(.ant-menu-horizontal) {
        padding: 20px 18px;
        line-height: 1;
        height: auto;
      }

      &.ant-menu-item-selected {
        background-color: transparent !important;
        color: $color-main;

        a {
          color: $color-main;
        }
      }

      &:hover,
      li:active {
        & > .ant-menu-title-content {
          color: $color-main;
          cursor: auto;
        }

        a {
          color: $color-link;
        }
      }
    }
  }
}

.ant-input {
  height: 34px;
  padding: 0 13px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 35px;
  padding: 0 13px;
}

.ant-select-selection-placeholder,
.ant-select-selection-item {
  line-height: 1;
  display: flex;
  align-items: center;
}

.ant-select {
  width: 100%;
}

.ant-picker {
  height: 35px;
}

.ant-input-affix-wrapper {
  padding: 0 13px;
  & > input.ant-input {
    padding: 0 9px;
  }
}

input {
  color: $color-main;
}

.reset-center {
  display: flex;
  align-items: center;
}
