@import '../../../scss//helpers';

.main {
  background: $main-bg;
  padding: $main-pending;
  margin-top: $header-height;
  height: 100%;
  min-height: 100%;

  & > div {
    background: $main-content-bg;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
}
