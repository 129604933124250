@import '../../../scss/helpers';

.tableCell {
  padding: 10px 15px;
  line-height: 19px;
}

.tableTh {
  @include font_table_th;

  cursor: pointer;
  border-radius: 4px;

  &:first-child {
    padding-left: $main-left-padding;
  }

  &:last-child {
    padding-right: $main-left-padding;
  }

  & > span {
    float: right;
  }

  &.activeSort {
    background-color: #f4f4f4;

    svg {
      path {
        fill: $color-grey;
      }
    }
  }
}

.tableTd {
  padding-top: 28px;
  padding-bottom: 28px;

  @include font_table_td;

  &:first-child {
    padding-left: $main-left-padding;
  }

  &:last-child {
    padding-right: $main-left-padding;
  }
}

.disabled {
  cursor: auto;
}
