.formHeader {
  position: relative;
  margin-bottom: 56px;

  .backArrow {
    position: absolute;
    top: 2.3px;
    left: -45px;
    cursor: pointer;
  }
}
