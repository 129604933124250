.formCell {
  width: 100%;
  margin-right: 11px;

  button {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
}
