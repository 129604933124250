.view {
  margin: 0 auto;

  &.viewList {
    width: 88%;
  }

  &.viewForm {
    width: 60%;
    padding: 26px 73px;
  }
}
